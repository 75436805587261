<template>
  <v-container fluid>
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title>
        <h3 class="font-weight-black display-0 basil--text">
          REPORTES DE PRODUCTO
        </h3>
      </v-card-title>
      <v-spacer></v-spacer>

    </v-card>
      <v-row >
        <v-col cols="100" sm="30"  md="4">
          <v-container >
            <v-card
                max-width="350"
                class="mx-auto"
            >
              <v-list two-line>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-incognito
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Productos por Vencer</v-list-item-title>
                    <v-list-item-subtitle>
                      Presentacion y laboratorio</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-bell-ring
                    </v-icon>
                  </v-list-item-icon>



                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                        rounded
                        color="primary"
                        dark
                        @click="getReportProductExpiration(user[0].area.entity.id)"
                    >
                      DESCARGAR
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-biohazard

                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Productos Vencidos</v-list-item-title>
                    <v-list-item-subtitle>
                      Presentacion y laboratorio</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                        rounded
                        color="primary"
                        dark
                        @click="getReportProductDefeated(user[0].area.entity.id)"
                    >
                      DESCARGAR
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-biohazard
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Productos con stock a tope</v-list-item-title>
                    <v-list-item-subtitle>
                      Considerar para hacer pedido</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                        rounded
                        color="primary"
                        dark
                        @click="getReportProductStockMin(user[0].area.entity.id)"
                    >
                      DESCARGAR
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>

              </v-list>
            </v-card>
          </v-container>
        </v-col>

        <v-col>
            <template>
              <v-card
                  class="mx-auto text-center"
                  color="blue"
                  dark
                  max-width="1200"
              >
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                        :value="value"
                        color="rgba(255, 255, 255, .7)"
                        height="120"
                        padding="25"
                        stroke-linecap="round"
                        smooth
                    >
                      <template v-slot:label="item">
                        ${{ item.value }}
                      </template>

                    </v-sparkline>
                  </v-sheet>
                </v-card-text>

                <v-card-text>
                  <div class="text-h4 font-weight-thin">
                    Productos mas vendidos los ultimos 7 dias
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="justify-center">
                  <v-btn
                      block
                      text
                      @click="getReportProductTopSales(user[0].area.entity.id)"
                  >
                    productos vendidos los ultimo 30 dias
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
        </v-col>

      </v-row>
    </v-container>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
import PDF from 'jspdf'
import 'jspdf-autotable';


export default {
  name: "Inventory",
  data: () => ({

    value: [ 9, 5, 10, 3, 5, 8, 9],

  }),

  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {

    getReportProductExpiration (id) {
      setTimeout(() => {
        axios.get(`api/report/productExpiration/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              /*this.responsable=response.data.responsable*/
              this.createPDFProductExpiration()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsProductExpiration() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        lot: 'LOTE',
        expiration: 'VENCIMIENTO',
        cantidad: 'CANTIDAD',
      }];
    },
    bodyRowsProductExpiration(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          lot: pdfBody[i].lot,
          expiration: pdfBody[i].expiration,
          cantidad: pdfBody[i].cantidad,
        });
      }
      return body;
    },
    createPDFProductExpiration () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'PRODUCTOS POR VENCER')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsProductExpiration(),
        body: this.bodyRowsProductExpiration(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 220,fontSize: 9, halign: 'left', fontStyle: 'bold'},
          1: {cellWidth: 75, halign: 'left'},
          2: {cellWidth: 80, halign: 'left'},
          3: {cellWidth: 40, valign: 'middle', halign: 'center'},
          4: {cellWidth: 50, valign: 'middle', halign: 'right'},
          5: {cellWidth: 60, halign: 'right'},
        }
      });

      doc.save(`ProductosPorVencer${Date.now()}.pdf`);
    },



    getReportProductDefeated(id) {
      setTimeout(() => {
        axios.get(`api/report/productDefeated/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              /*this.responsable=response.data.responsable*/
              this.createPDFProductDefeated ()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsProductDefeated() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        lot: 'LOTE',
        expiration: 'VENCIMIENTO',
        cantidad: 'CANTIDAD',
      }];
    },
    bodyRowsProductDefeated (rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          lot: pdfBody[i].lot,
          expiration: pdfBody[i].expiration,
          cantidad: pdfBody[i].cantidad,
        });
      }
      return body;
    },
    createPDFProductDefeated  () {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'PRODUCTOS VENCIDOS')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsProductDefeated (),
        body: this.bodyRowsProductDefeated (this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 220,fontSize: 9, halign: 'left', fontStyle: 'bold'},
          1: {cellWidth: 75, halign: 'left'},
          2: {cellWidth: 80, halign: 'left'},
          3: {cellWidth: 40, valign: 'middle', halign: 'center'},
          4: {cellWidth: 70, valign: 'middle', halign: 'right'},
          5: {cellWidth: 40, halign: 'right'},
        }
      });

      doc.save(`ProductosVencidos${Date.now()}.pdf`);
    },


    getReportProductStockMin(id) {
      setTimeout(() => {
        axios.get(`api/report/productStockMin/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              /*this.responsable=response.data.responsable*/
              this.createPDFProductStockMin()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsProductStockMin() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        cantidad: 'CANTIDAD EN FARMACIA',
        stockMin: 'STOCK MINIMO DEL PRODUCTO',
      }];
    },
    bodyRowsProductStockMin(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          cantidad: pdfBody[i].cantidad,
          stockMin: pdfBody[i].stockMin,

        });
      }
      return body;
    },
    createPDFProductStockMin() {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'PRODUCTOS CON STOCK MINIMO A TOPE')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsProductStockMin(),
        body: this.bodyRowsProductStockMin(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 220,fontSize: 9, halign: 'left', fontStyle: 'bold'},
          1: {cellWidth: 75, halign: 'left'},
          2: {cellWidth: 80, halign: 'left'},
          3: {cellWidth: 70, valign: 'middle', halign: 'center'},
          4: {cellWidth: 70, valign: 'middle', halign: 'right'},

        }
      });

      doc.save(`ProductosStockMin${Date.now()}.pdf`);
    },


    getReportProductTopSales(id) {
      setTimeout(() => {
        axios.get(`api/report/productTopSales/${id}`)
            .then((response) => {
              this.listReport = response.data.details
              this.sucursal=response.data.sucursal
              /*this.responsable=response.data.responsable*/
              this.createPDFProductTopSales()
            })
            .catch(error => console.log(error))
      }, 1000)
    },

    headRowsProductTopSales() {
      return [{
        name: 'NOMBRE DEL PRODUCTO',
        lab: 'LABORATORIO',
        present: 'PRESENTACION',
        cantidad: 'CANTIDAD VENDIDA',

      }];
    },
    bodyRowsProductTopSales(rowCount, pdfBody) {
      rowCount = rowCount || 10;
      let body = [];
      for (let i = 0; i < pdfBody.length; i++) {
        body.push({
          name: pdfBody[i].name,
          lab: pdfBody[i].lab,
          present: pdfBody[i].present,
          cantidad: pdfBody[i].cantidad,

        });
      }
      return body;
    },
    createPDFProductTopSales() {
      let doc = new PDF("p", "pt", "letter");
      let header = () => {

        let header_1 = () => {
          doc.setFontSize(10);
          doc.text(39, 40, 'PRODUCTOS MAS VENDIDOS LOS ULTIMOS 30 DIAS')
        };
        doc.autoTable({didDrawPage : header_1});

        let header_2 = () => {
          doc.setFontSize(8);
          doc.text(39, 50, 'SysPharma 1.0')
        };
        doc.autoTable({didDrawPage : header_2});

        let today = new Date();
        let header_3 = () => {
          doc.setFontSize(9);
          doc.text(450, 30, `Fecha: ${today.getDate()<10 ? '0'+today.getDate() : today.getDate()}/${today.getMonth()<10 ? '0'+today.getMonth():today.getMonth()}/${today.getFullYear()<10 ? '0'+today.getFullYear():today.getFullYear()}`)
        };
        doc.autoTable({didDrawPage : header_3});

        let header_4 = () => {
          doc.setFontSize(9);
          doc.text(450, 40, `Hora: ${today.getHours()<10 ? '0'+today.getHours() : today.getHours()}:${today.getMinutes()<10 ? '0'+today.getMinutes():today.getMinutes()}:${today.getSeconds()<10 ? '0'+today.getSeconds():today.getSeconds()}`)
        };
        doc.autoTable({didDrawPage : header_4});

        let header_5 = () => {
          doc.setFontSize(9);
          doc.text(450, 50, `Página ${doc.internal.getNumberOfPages()}`);
        };
        doc.autoTable({didDrawPage : header_5});

        let header_6 = () => {
          doc.setFontSize(9);

          const sucursal = this.sucursal[0].name;
          doc.text(39, 60, `${sucursal}`)

        };
        doc.autoTable({didDrawPage : header_6});

      };

      console.error = () => {};
      doc.autoTable({
        didDrawPage : header,
        margin: {top: 100},
        styles: {
          cellPadding: 1,
          fontSize: 8,
          halign: 'center'

        },
        theme: 'grid',
        head: this.headRowsProductTopSales(),
        body: this.bodyRowsProductTopSales(this.countRow, this.listReport),

        bodyStyles: {
          margin: 20,
          fontSize: 8,
        },
        columnStyles: {
          0: {cellWidth: 240,fontSize: 9, halign: 'left', fontStyle: 'bold'},
          1: {cellWidth: 95, halign: 'left'},
          2: {cellWidth: 95, halign: 'left'},
          3: {cellWidth: 70, valign: 'middle', halign: 'center'},


        }
      });

      doc.save(`ProductosSTopProductosVentas${Date.now()}.pdf`);
    },










  }
}
</script>
